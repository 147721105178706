body {
    margin: 0;
    padding: 0;
    height: 100vh;
    background-image: url('../images/07. Home Decor.png');
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero-container {
    text-align: center;
    font-size: 196px; 
    font-family: 'SVGFESpotLightElement'; 
    color: #424241; 
    opacity: 0.2; 
}


.login-page {
   

    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}



.v {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
}
