/* AuthLogin.css */

.button {
    background-color: #EC1D3B;
    color: #ffffff;
    padding: 10px 20px;
    font-size: 22px;
    border: none;
    border-radius: 12px;
    cursor: pointer;
   


}
